<template>
  <div class="page-header">
    <!-- logo部分 -->
    <div class="page-header-logo">
      <a class="page-header-logo-coding" href="/"></a>
    </div>
    <div class="intro-page">
      <div class="right-header">
        <!-- 赛项介绍页对顶部导航栏有个模糊背景样式 -->
        <div class="bg-top" v-if="($route.matched.some(r => r.path == '/intro'))"></div>
        <!-- 顶部菜单 -->
        <ul class="page-header-menu">
          <li
            v-for="(m, mdx) in menuList"
            :key="mdx"
            :title="m.name"
            class="page-header-menu-item"
            :class="($route.matched.some(r => r.path == m.url) || $route.path == m.url) ? 'current' : ''">
              <router-link
                  :to="{ path: m.url }"
                  class="page-header-menu-item-link">
                  {{ m.name }}
              </router-link>
          </li>
        </ul>
        <!-- 个人中心 -->
        <div class="page-header-toolbar" @click="openLogin" title="去后台">
          去后台
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  components: {
    // Login
  },
  data() {
    return {
      menuList: [
        {url: "/", name: "首页"},
        {url: "/activities", name: "赛事活动"},
        {url: "/intro", name: "赛项介绍"},
        {url: "/lesson", name: "在线课程"},
        {url: "/resource", name: "资源中心"},
        {url: "/news", name: "新闻中心"},
      ],
      currentMenu: "/", // 当前选中的菜单序号
    }
  },
  methods: {
    // 打开登录弹窗
    openLogin() {
      // this.$router.push({
      //   path: "/login"
      // })
      window.open(this.$router.resolve({name: 'Login'}).href, '_blank');
    },
    gotoPage(url) {
      this.$router.push({
        path: url
      })
    },
    handleCommand(command) {
      switch(command) {
        case "self": // 个人中心
            this.$router.push({
              path: "/personal"
            })
            break;
        case "message": // 消息中心
            break;
        case "feedback": // 用户反馈
            break;
        case "logout": // 退出登录
            this.$store.dispatch("user/logout")
            break;
        default: break;
      }
    },
  },
  watch: {
    // token变化 更新顶部菜单
    "$store.getters.token": {
      handler(n, o) {

      },
      immediate: true
    }
  },
}
</script>

<style scoped lang="scss">
.page-header {
  height: 0.8rem;
  width: 100%;
  background-color: #fff;
  padding: 0 0 0 0.32rem;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  &:before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      right: 0;
      height: 12rem;
      z-index: 0;
      filter: blur(0.18rem);
  }
  .page-header-logo {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    .page-header-logo-coding {
        background-image: url('../../assets/images/logo.png');
        width: 1.28rem;
        height: 0.48rem;
        background-size: 1.28rem 0.48rem;
    }
  }
  .intro-page {
    flex: 1;
    overflow: hidden;
    .right-header {
      float: right;
      position: relative;
      min-width: 8rem;
      display: flex;
      .bg-top {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #B6D0E2;
        -webkit-filter: blur(0.18rem);
        -moz-filter: blur(0.18rem);
        -o-filter: blur(0.18rem);
        -ms-filter: blur(0.18rem);
        filter: blur(0.18rem);
        z-index: 0;
      }
      .page-header-menu {
        display: flex;
        flex: 1;
        flex-flow: row wrap;
        align-items: center;
        justify-content: right;
        z-index: 1;
        .page-header-menu-item {
          position: relative;
          .page-header-menu-item-link {
            color: #15181D;
            padding: 0 0.24rem;
            line-height: 0.8rem;
            display: block;
            cursor: pointer;
            font-size: 0.16rem;
          }
          &.current, &:hover {
            &:after {
              content: "";
              position: absolute;
              margin-left: 50%;
              left: -0.16rem;
              bottom: 0;
              width: 0.32rem;
              height: 0.02rem;
              background: #EB6B6A;
              border-radius: 0rem 0rem 0rem 0rem;
            }
          }
        }
      }
      .page-header-toolbar {
        z-index: 1;
        margin-right: 0.32rem;
        font-weight: 400;
        font-size: 0.16rem;
        color: #FFFFFF;
        line-height: 0.4rem;
        margin-top: 0.2rem;
        cursor: pointer;
        width: 0.8rem;
        height: 0.4rem;
        background: #EB6B6A;
        border-radius: 0.04rem 0.04rem 0.04rem 0.04rem;
        text-align: center;
      }
    }
  }
}
</style>
