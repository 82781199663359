import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import { getToken } from '@/utils/auth'
import { decryptWithAes, decryptBase64 } from '@/utils/crypto';
import { encrypt, decrypt } from '@/utils/jsencrypt';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['clientid'] = process.env.VUE_APP_CLIENT_ID;
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, //process.env.VUE_APP_BASE_URL, // + process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 50000,
  headers: {}
})

const errorCode = {
  '401': '认证失败，无法访问系统资源',
  '403': '当前操作没有权限',
  '404': '访问资源不存在',
  'default': '系统未知错误，请反馈给管理员'
}

instance.interceptors.request.use(
  config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    // 是否需要加密
    const isEncrypt = (config.headers || {}).isEncrypt === 'true';

    // 请求头携带token
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    // headers中配置serialize为true开启序列化
    if (config.headers.serialize) {
      config.data = qs.stringify(config.data, {arrayFormat: 'indices', allowDots: true})
    }

    // 当开启参数加密
    if (isEncrypt && (config.method === 'post' || config.method === 'put')) {
      // 根据 AES 密钥进行加密
      const publicKey = (config.headers || {}).publicKey
      config.data = typeof config.data === 'object' ? encrypt(encodeURIComponent(JSON.stringify(config.data)), publicKey) : encrypt(encodeURIComponent(config.data), publicKey);
      delete config.headers['publicKey'];
    }
    // FormData数据去请求头Content-Type
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }

    return config
  },
  err => {
    return Promise.reject(err);
  }
);
instance.interceptors.response.use(
  response => {
    // 加密后的 AES 秘钥
    const keyStr = response.headers[process.env.VUE_APP_ENCRYPT_HEADER];
    // 加密
    if (keyStr != null && keyStr != '') {
      const data = response.data;
      // 请求体 AES 解密
      const base64Str = decrypt(keyStr);
      // base64 解码 得到请求头的 AES 秘钥
      const aesKey = decryptBase64(base64Str.toString());
      // aesKey 解码 data
      const decryptData = decryptWithAes(data, aesKey);
      // 将结果 (得到的是 JSON 字符串) 转为 JSON
      response.data = JSON.parse(decryptData);
    }

    // 二进制数据则直接返回
    if(response.request.responseType ===  'blob' || response.request.responseType ===  'arraybuffer'){
      return response.data
    }

    // 未设置状态码则默认成功状态
    const code = response.data['code'] || 200
    // 获取错误信息
    const msg = errorCode[code] || response.data.msg || errorCode['default']


    if ( code == 200 ) {
        return Promise.resolve(response.data)
    } else {
        Vue.prototype.$message({type: "error", message: msg});
        return Promise.reject(response.data)
    }
  },
  error => {
    let { message, response } = error;
    if (message == "Network Error") {
        message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
        message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Vue.prototype.$message({type: "error", message: message});
    return Promise.reject(error)
  }
);

export default instance
