// import crypto from 'crypto'

// 防抖函数
export const debounce = (fn, wait) => {
    let timeout = null;
    return function() {
        let context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn.apply(context, args);
        }, wait);
    };
}

// export function rsaEncryptStr (publicKey, text) {
//     let pubKey = '-----BEGIN PUBLIC KEY-----' + publicKey + '-----END PUBLIC KEY-----'
//     let bufferText = Buffer.from(text, 'utf8')
//     return crypto.publicEncrypt({ key: pubKey, padding: crypto.constants.RSA_PKCS1_PADDING }, bufferText).toString('base64')
// }

/**
 * 手机号校验
 * @param {phone} phone 手机号
 */
export function phoneCheck(phone) {
  return /^1\d{10}$/.test(phone)
}

// 将时间转化为时分秒
export function formatSeconds (theTime) {
  let theTime1 = 0
  let theTime2 = 0
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60)
      theTime1 = parseInt(theTime1 % 60)
    }
  }
  function formatNum(num) {
    if (num < 10) {
      return "0" + num
    }
    return num
  }
  return {
    hour: formatNum(parseInt(theTime2)),
    min: formatNum(parseInt(theTime1)),
    second: formatNum(parseInt(theTime))
  }
}

/**
 * 判断当前平台是PC还是移动端
 */
export function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}