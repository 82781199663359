import request from '@/utils/request'
// pc端固定客户端授权id
const encryptHeader = process.env.VUE_APP_ENCRYPT_HEADER;

export function genKeyPair(callback) {
    return request({
        url: '/genKeyPair',
        method: 'get',
        headers: {
            isToken: false,
            repeatSubmit: false
        },
    }).then((res) => {
        return callback(res.data.uuidPrivateKey, res.data.RSA_PUBLIC_KEY)
    })
}

// 登录
export function login(userInfo) {
    return new Promise((resolve, reject) => {
        genKeyPair((uuid, public_key) => {
            request({
                url: '/auth/login',
                headers: {
                    isToken: false,
                    isEncrypt: true,
                    [encryptHeader]: uuid,
                    publicKey: public_key,
                    repeatSubmit: false
                },
                method: 'post',
                data: userInfo
            }).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    });
}

// 退出登录
export function logout(data) {
    return request({
        url: '/calendar/allHistorical',
        method: 'post',
        data: data,
        headers: {
            serialize: true
        }
    })
}

// 获取用户信息
export function getUserInfo() {
    return request({
        url: '/system/user/getInfo',
        method: 'get'
    })
}